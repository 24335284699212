<template>
  <v-card>
    <v-card-title>
      {{ listPremios[0][0].prizes.description }}
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="itemsTable"
      :single-expand="singleExpand"
      show-expand
      hide-default-footer
      dense
      :items-per-page="listPremios.length"
      class="elevation-0 table-premios"
    >

      <template v-slot:item.prize="{ item }">
        {{ item[0].prizes.number }}
      </template>

      <template v-slot:item.value="{ item }">
        {{ item[0].prizes.value | currency }}
      </template>

      <template v-slot:item.ball="{ item }">
        {{ getDrawnBalls(item) }}
      </template>
      <!-- <template
        v-slot:body="{ items }"
      >
        <tbody>
          <tr
            v-for="(item, idx) in items"
            :key="idx"
            class="box-table-row"
          >
          <td></td>
          <td>{{ idx + 1 | ordinal }}</td>
          <td>{{ item[0].prizes.value | currency }}</td>
        </tr>
        </tbody>
      </template> -->


      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="table-premios-td">
          <v-data-table
            :headers="headers2"
            :items="itensExpanded(item)"
            hide-default-footer
            dense
            :items-per-page="item.length"
          >
            <template v-slot:item.cod="{ item }">
              {{ item.entity.cod }}
            </template>

            <template v-slot:item.valuePrize="{ item }">
              {{ item.prizeValue | currency }}
            </template>

            <template v-slot:item.gameCod="props">
              <v-edit-dialog 
                class="modal-palpites"
                min-width="300px"
              >
                <v-btn class="mt-2 mb-2">{{ props.item.lotteryGameCod }}</v-btn>
                <template v-slot:input>
                  <div class="cartela-bingo">
                    <template v-for="(v, c) in getPalpites(props.item.guess.guess)">
                      <div class="d-flex" :key="`$item-${c}`">
                        <template v-for="(d, e) in v">
                          <div v-if="d !== '00'" :key="`v-${e}`" :class="{'cartela-head-default':true }" >
                            <input type="checkbox"  disabled>
                            <label> {{ d }}<span></span></label>
                          </div>
                          <div v-else :key="e" :class="{'cartela-head-default':true }" >
                              <img src="/static/hand_64x64.png" width="20" :style="{position: 'relative', top: '-1px', right: '-1px  '}">
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
              </v-edit-dialog>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: 'ResultadoLoteriasBingos',
  props: {
    listPremios: {
      type: Array,
      default: () => []
    },

    results: {
      type: Object || Array,
      default: () => []
    }
  },
  data: () => ({
    headers: [
      { sortable: false, align: 'start', class: 'table-header', text: 'Numero Prêmio', value: 'prize' },
      { sortable: false, align: 'start', class: 'table-header', text: 'Valor', value: 'value' },
      { sortable: false, align: 'start', class: 'table-header', text: 'Bola', value: 'ball' },
    ],
    headers2: [
      { sortable: false, align: 'start', class: 'table-header', text: 'Côdigo Revenda/Usuârio', value: 'cod' },
      { sortable: false, align: 'start', class: 'table-header', text: 'Côdigo Bilhete', value: 'gameCod' },
      { sortable: false, align: 'start', class: 'table-header', text: 'Valor Prêmio', value: 'valuePrize' },
    ],
    expanded: [],
    singleExpand: true,
  }),

  computed: {
    itemsTable () {
      const arr = []
      this.listPremios.forEach(item => arr.push({ ...item }))
      return arr
    },
  },
  methods: {
    itensExpanded (item) {
      return Object.values(item)
    },

    getPalpites(palpites) {
      return palpites.match(new RegExp('.{10}', 'g')).map(v => v.match(new RegExp('.{2}', 'g')))
    },

    itemExpandRequest(item) {
      this.expanded = item.item[0]
    },

    getDrawnBalls (results) {
      if (this.results.drawnBalls) {
        return this.results.drawnBalls
      }

      const result = this.results.find(item => item.prizeNumber === results[0].prizeNumber)

      return result ? result.drawnBalls : 0
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-premios::v-deep {
    .v-data-table__expanded__content {
      box-shadow: none;
      padding: 60px;
      background: #f1f1f1;
    }
    .table-premios-td {
      padding: 10px;
      .v-data-table__wrapper {
        box-shadow: 2px 2px 5px rgba(0, 0, 0, .2);
      }
    }
  }

  .cartela-bingo {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 260px;
  }

  .v-application::v-deep .v-small-dialog__menu-content {
    min-width: 330px !important;
  }

.cartela {
  &-margin {
    margin: 5px;
  }
  &-head-numero {
    padding-top: 10px;
    float: left;
    padding-top: 8px;
    display: block;
  }
  &-content {
    width: calc(100% / 3);
    @media (max-width: 1190px) {
      width: calc(100% / 3);
    }
    @media (max-width: 900px) {
      width: calc(100% / 2);
    }
    @media (max-width: 500px) {
      width: 100%;
    }
    display: inline-block;
    float: left;
  }
  &-init {
    overflow: auto;
    width: 100%;
    padding: 5px;
  }
  // &-ganhou{
  //   &-0{
  //     background-image: linear-gradient(to top, #800101, #f50e0e) !important;
  //   }
  //   &-1{
  //     background-image: linear-gradient(to top, #550aa1, #a10ce1) !important;
  //   }
  //   &-2{
  //     background-image: linear-gradient(to top, #0c9a00, #9adf07) !important;
  //   }
  //   &-3{
  //     background-image: linear-gradient(to top, #d66100, #ffde00) !important;
  //   }
  //   &-4{
  //     background-image: linear-gradient(to bottom, #00bff1, #003ab5) !important;
  //   }
  // }
  &-head {
    display: flex;
    &-letter {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      width: 100%;
      color: white;
      background-image: linear-gradient(to top, #800101, #f50e0e);
      &-1 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #800101, #f50e0e);
      }
      &-2 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #550aa1, #a10ce1);
      }
      &-3 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #0c9a00, #9adf07);
      }
      &-4 {
        // @extend %default-head;
        background-image: linear-gradient(to top, #d66100, #ffde00);
      }
      &-5 {
        // @extend %default-head;
        background-image: linear-gradient(to bottom, #00bff1, #003ab5);
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 1;
        left: 5;
        transform: translate(-50%, -50%);
      }
    }
    &-default {
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      width: 100%;
      height: 33px;
      color: #4b4b4b;
      font-weight: 600;
      background: white;
      font-size: 16px;
      border: 1px solid #4b4b4b;

      @media (max-width: 769px) {
        font-size: 12px;
      }

      input {
        display: none;
      }
      input:checked + label {
        color: white;
        font-weight: bold;
        display: block;
        width: 100%;
        position: relative;
        z-index: 1;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          padding-bottom: 28px;
          border-radius: 50%;
          width: 28px;
          position: absolute;
          background: #070988;
          z-index: -1;
          display: block;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &-content-linha {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
  }
  // &-linha-fechada{
  //   background: #a0a0a0 !important;
  // }
  &-linha {
    width: 100%;
    display: flex;
    width: 100%;
  }
  &-numeros {
    background: white;
    position: relative;
    span {
      font-weight: bold;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      width: 100%;
    }
  }
  /* Marca cartelas durante o sorteio */
  &-fechada {
    // @extend %cartelas-marcadas;
  }
  &-disable {
    .cartela-head-default {
      background-image: linear-gradient(to bottom, #fafafa, #666) !important;
      input:checked + label {
        color: #d1d1d1;
      }
      input:checked + label {
        color: #d1d1d1;
      }
      input:checked + label span {
        background: #686868;
      }
    }
  }
  &-ganhou-linha {
    // @extend %cartelas-marcadas;
  }
  /* Animação das 5 primeiras bolas */
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

</style>